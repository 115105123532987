@import 'common/modern.css';
@import 'common/core.css';


.sidebar .sidebar-titleSection {
    font-weight: 500;
    color: #ffffff;
  }
.sidebar-user{
  font-size: x-large;
}  
.sidebar .icon-logo{
  width: 12%;
}

.add-dependent .modal-body{
  max-height: 400px; 
  overflow-y: auto; 
}