.index-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btns-title {
  display: flex;
  justify-content: flex-end;
  width: calc(100% - 339px);
}

.btns-title .btn {
  margin-bottom: 0;
}

.btns-title > a:first-child {
  margin-right: 12px;
}

@media screen and (max-width: 991px) {
  .index-header {
    flex-direction: column;
    width: 100%;
  }

  .btns-title {
    width: 100%;
    margin-top: 1rem;
    justify-content: center;
  }
}

@media screen and (max-width: 448px) {
  .btns-title {
    flex-direction: column;
    align-items: center;
  }
  .btns-title .btn {
    margin-bottom: 12px;
    width: 200px;
  }
  .btns-title > a:first-child {
    margin-right: 7px;
  }
}