.appointment_details .tab {
    margin-bottom: 10px;
  }
  
  .appointment_details .card {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  
  .appointment_details_profile_image {
    height: 65px;
    width: 65px;
  }
  
  @media (max-width: 768px) {
    .appointment_details .no-image {
      margin-bottom: 15px;
    }
    .appointment_details .appointment_details_content {
      margin-left: 0;
      margin-top: 15px;
    }
  }
  
  .appointment_details_content {
    margin-left: 10px;
  }
  
  .appointment_details .card-body {
    padding: 7px;
  }
  
  .appointment_details_profile_image img {
    width: 100%;
    height: 100%;
  }
  
  .appointment_tabs .tab .nav-tabs .nav-link {
    background: transparent;
    color: #343a40;
    padding: 5px 15px;
    font-size: 12px;
    border: 0;
    cursor: pointer;
  }
  
  .appointment_tabs .tab .nav-tabs .nav-link.active {
    background: white;
    border-radius: 5px;
    color: #46b7ee;
    -webkit-box-shadow: 1px 1px 4px #0000002b;
            box-shadow: 1px 1px 4px #0000002b;
  }
  
  .appointment_tabs .tab .tab-content {
    -webkit-box-shadow: 1px 1px 4px #0000002b;
            box-shadow: 1px 1px 4px #0000002b;
    border-radius: 5px;
    margin-top: 10px;
    min-height: 250px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 250px;
  }
  
  .lh-10 {
    line-height: 10px;
  }
  
  .pull-right {
    float: right;
  }

  .download-btn-center{
    margin-left: 43%;
  }
  
  .MedicationsTableExpands span {
    background: #e8f5ef;
    display: inline-block;
    padding: 6px 10px;
    line-height: 12px;
    font-size: 12px;
    color: #116f45;
    margin-right: 10px;
    height: 24px;
    margin-top: 10px;
    min-width: 46%;
    border-radius: 3px;
  }
  
  .appointment_tabs .tab .tab-content::-webkit-scrollbar {
    width: 1em;
  }
  
  .appointment_tabs .tab .tab-content.disable-scrollbars::-webkit-scrollbar {
    width: 0;
  }
  
  .print-button {
    color: #46b7ee;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #46b7ee;
  }
  
  .print-button:hover, .print-button:active, .print-button:focus {
    color: #fff;
    background-color: #46b7ee;
    border: 1px solid #46b7ee;
  }
  
  .appointment_tabs .tab .tab-content::-webkit-scrollbar-thumb {
    background-color: #46b7ee;
    outline: 1px solid slategrey;
  }
  
  h5.nodatafound {
    text-align: center;
    background: #f3f3f3;
    padding: 10px;
    border: 1px solid #eaeaea;
    border-radius: 5px;
  }
  
  .PrescriptionMedicationTable .table th {
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
  }
  
  .PrescriptionMedicationTable .table td {
    font-size: 12px;
    padding: 5px 10px;
  }
  
  .prescription_notes {
    height: auto !important;
  }
  
  .PrescriptionMedicationTable .table {
    background: #f5f5f5;
    border: 1px solid #eaeaea;
    margin-top: 3px;
  }
  
  @media (min-width: 576px) {
    .appointment_details_modal {
      max-width: 50%;
    }
  }
  
  .consultation-card-body {
    min-height: 12em;
    max-height: auto;
  }